import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IModalContext, ModalChildComponentContextKey } from '../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { asModalComponent } from '../../Modal';
import { SendMessageComposer } from '../../email/SendMessageComposer';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, IModalContext, IUserSessionComponentProps {
	automation?: Api.AutomationViewModel;
	className?: string;
	contact?: Api.ContactViewModel;
	emailStepStatus?: Api.IAutomationStepStatus<Api.IEmailAutomationStep>;
	saveCtaTitle?: string;
	styles?: StyleDeclarationValue[];
}

class _AutomationStepStatusEmailEditor extends React.Component<IProps> {
	private mEmailMessage: Api.AutomationStepStatusEmailMessageViewModel;
	constructor(props: IProps) {
		super(props);
		this.mEmailMessage = new Api.AutomationStepStatusEmailMessageViewModel(
			props.userSession,
			props.emailStepStatus,
			props.automation
		);

		this.mEmailMessage.contactsToAdd.add(props.contact);
	}

	public render() {
		const { className, styles, saveCtaTitle } = this.props;
		return (
			<div className={`${css(...(styles || []))} automation-step-status-email-editor ${className || ''}`}>
				<SendMessageComposer
					canScheduleSend={false}
					emailMessage={this.mEmailMessage}
					hideCcOption={true}
					onRequestRemoveSavedEmailAttachment={this.onRequestRemoveSavedEmailAttachment}
					onWillSend={this.onSave}
					sendCtaTitle={saveCtaTitle || 'Done'}
				/>
			</div>
		);
	}

	private onSave = () => {
		const { parentModal, logApiError, logInput, emailStepStatus } = this.props;
		// override default action
		const promise = this.mEmailMessage.update();
		if (promise) {
			logInput('UpdateEmailStepStatus', 'Click', {
				emailStepStatusId: emailStepStatus?.id,
			});
			promise
				.then(() => {
					parentModal?.onRequestClose(null, false);
				})
				.catch((error: Api.IOperationResultNoValue) => {
					logApiError('UpdateEmailStepStatus-Error', error);
				});
		}
		return false;
	};

	private onRequestRemoveSavedEmailAttachment = (savedAttachment: Api.IFileAttachment) => {
		return this.mEmailMessage.removeAttachment(savedAttachment);
	};
}

const AutomationStepStatusEmailEditorAsObserver = observer(_AutomationStepStatusEmailEditor);
const AutomationStepStatusEmailEditorWithContext = inject(
	ModalChildComponentContextKey,
	UserSessionViewModelKey
)(AutomationStepStatusEmailEditorAsObserver);
export const AutomationStepStatusEmailEditor = withEventLogging(
	AutomationStepStatusEmailEditorWithContext,
	'AutomationStepStatusEmailEditor'
);

export const AutomationStepStatusEmailEditorModal = asModalComponent(AutomationStepStatusEmailEditor, {
	className: css(styleSheet.modal),
	shouldCloseOnOverlayClick: false,
	useDefaultHeader: true,
});
