import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ConversationThreadEventViewModel, IFileAttachmentWithURL } from '../../../../extViewmodels';
import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages, useTextMessaging } from '../../../../models/hooks/appStateHooks';
import { useLambda } from '../../../../models/hooks/useLambda';
import { MessageBubble } from '../../texting/MessageBubble';
import { EntityConversationComposerModal } from './presentation';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	entity?: Api.IEntity;
	event?: ConversationThreadEventViewModel;
	styles?: StyleDeclarationValue[];
}

export const EntityConversationContent: React.FC<IProps> = observer(props => {
	const { className, styles = [], event, entity } = props;
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging();
	const [isOpen, , setOpen] = useLambda(false);
	const textMessagingVm = useTextMessaging();

	const [conversation, setConversation] = React.useState<Api.ConversationViewModel>(null);
	const shouldRemoveConversationOnCloseRef = React.useRef(false);

	React.useEffect(() => {
		if (!isOpen) {
			return;
		}

		let promise: Promise<Api.ConversationViewModel> = null;
		if (conversation) {
			promise = Promise.resolve(conversation);
		} else {
			shouldRemoveConversationOnCloseRef.current = !textMessagingVm.hasConversation(event.resourceId);

			promise = textMessagingVm.getConversation(event.resourceId);
			promise?.then(convo => {
				setConversation(convo);
			});
		}

		promise
			?.then(convo => {
				const p = convo.load();
				p.then(() => {
					convo.markAsRead().catch(err => {
						logApiError('ConversationMarkAsRead-Error', err);

						errorMessages.pushApiError(err);
					});
				}).catch(/** Eat this error, handled by catch below */ Api.VmUtils.Noop);
				return p;
			})
			?.catch(err => {
				logApiError('LoadConversationInModal-Error', err);

				errorMessages.pushApiError(err);
			});

		return () => {
			if (!!conversation && shouldRemoveConversationOnCloseRef.current) {
				textMessagingVm.removeConversation(conversation);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [conversation, isOpen]);

	const onRequestClose = React.useCallback(
		(didSend: boolean) => {
			// Do not remove if there was a message sent.
			// The API has an implicit behavior to automatically restore conversations when a new message is recorded (inbound and outbound).
			if (shouldRemoveConversationOnCloseRef.current && didSend) {
				shouldRemoveConversationOnCloseRef.current = false;
			}
			setOpen(false)();
		},
		[setOpen]
	);

	return (
		<div className={`${css(styleSheet.container, ...styles)} ${className || ''}`}>
			<div className={css(styleSheet.textBubbleContainer)}>
				{event.lastMessages
					?.filter(
						(message, idx, self) =>
							idx === self.findIndex(m => m.id === message.id) && (message.media?.length || message.text)
					)
					?.map(x => {
						if (x.media?.length) {
							const file = x.media?.[0] as IFileAttachmentWithURL;
							return (
								<MessageBubble
									textMessage={x}
									id={x.id}
									key={x.id}
									showAvatar={true}
									showTimeStamp={true}
									mediaContent={{ ...file, url: file?.storedFile?.publicUrl }}
									entity={entity}
								/>
							);
						}
						return (
							<MessageBubble
								textMessage={x}
								id={x.id}
								key={x.id}
								showAvatar={true}
								showTimeStamp={true}
								entity={entity}
							/>
						);
					})}
				<div className={css(styleSheet.textShowMore)}>
					<button onClick={setOpen(true)}>Show More</button>
				</div>
			</div>
			<EntityConversationComposerModal
				conversation={conversation}
				modalProps={{
					className: css(styleSheet.modalClassName),
					isOpen,
					onRequestClose,
				}}
			/>
		</div>
	);
});
