import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { IEmojiData } from 'emoji-picker-react';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ChangeEvent, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { debounce } from '../../../../models/UiUtils';
import { useTextMessaging } from '../../../../models/hooks/appStateHooks';
import { brandPrimaryHover, navigation, oldSilver } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { ConfirmationDialog } from '../../ConfirmationDialog';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { AttachIcon } from '../../svgs/icons/AttachIcon';
import { DoubleArrowIcon } from '../../svgs/icons/DoubleArrowIcon';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import { EmojiPickerButton } from '../EmojiPickerButton';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps {
	className?: string;
	conversation?: Api.ConversationViewModel;
	onSend?(msg: string, attachments: Api.AttachmentsToBeUploadedViewModel<File>): Promise<void>;
	hideTextSend?: boolean;
}

const TextMessageInputBase: React.FC<IProps> = ({
	className = '',
	onSend,
	logApiError,
	conversation,
	hideTextSend,
}) => {
	const textMessageVm = useTextMessaging();
	const [message, setMessage] = useState('');
	const [showingSizeLimitWarning, setShowingSizeLimitWarning] = useState(false);
	const [, forceUpdate] = useReducer(x => x + 1, 0);
	const attachmentsToBeSent = useRef<Api.AttachmentsToBeUploadedViewModel<File>>(
		new Api.AttachmentsToBeUploadedViewModel(null, Api.TextMessagingMaxFileByteSize)
	).current;
	const filesImportRef = useRef<HTMLInputElement>(null);
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const hasLetters = /[a-zA-Z]/;
	const hasNumbers = /[0-9]/;
	const hasSymbols = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

	const adjustHeight = useCallback(() => {
		setTimeout(() => {
			if (textAreaRef.current) {
				textAreaRef.current.style.height = '0px';
				textAreaRef.current.style.height = `${2 + textAreaRef.current.scrollHeight}px`;
			}
		}, 0);
	}, []);

	const adjustFont = React.useCallback((isEmoji: boolean) => {
		if (textAreaRef.current) {
			textAreaRef.current.style.fontSize = isEmoji ? '40px' : '14px';
			adjustHeight();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setMessage('');
		attachmentsToBeSent.remove(attachmentsToBeSent.attachments);
		adjustHeight();
		adjustFont(false);
	}, [adjustFont, adjustHeight, setMessage, attachmentsToBeSent]);

	useEffect(() => {
		const textArea = textAreaRef.current;
		const handler = (e: ClipboardEvent) => {
			const files = e.clipboardData.files;
			const fileNames = Array.from(files).map(file => file.name);
			addFilesToAttachments(files);

			setTimeout(() => {
				const target = e.target as HTMLTextAreaElement;

				let currentInputText = target.value;
				fileNames.forEach(fileName => {
					currentInputText = currentInputText.replace(fileName, '');
				});
				setMessage(currentInputText.replace(/\n+$/, ''));
			}, 0);
		};

		textArea.addEventListener('paste', handler);

		return () => {
			textArea.removeEventListener('paste', handler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateTypingindicator = React.useMemo(
		() =>
			debounce(() => {
				if (conversation?.id) {
					textMessageVm?.updateTypingIndicator(conversation.id)?.catch(() => {
						// eat this error
					});
				}
			}, 100),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[conversation?.id]
	);

	const onInputChange = React.useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
		const isEmoji =
			e.target.value.length > 0 &&
			e.target.value.length <= 8 &&
			!hasLetters.test(e.target.value) &&
			!hasNumbers.test(e.target.value) &&
			!hasSymbols.test(e.target.value);
		adjustFont(isEmoji);
		setMessage(e.target.value);
		updateTypingindicator();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onAttachClick = React.useCallback(() => {
		filesImportRef?.current?.click();
	}, []);

	const onEmojiClick = React.useCallback(
		(_: React.MouseEvent<Element, MouseEvent>, data: IEmojiData) => {
			const isEmoji =
				message.length <= 8 && !hasLetters.test(message) && !hasNumbers.test(message) && !hasSymbols.test(message);
			adjustFont(isEmoji);
			setMessage(`${message}${data.emoji}`);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[adjustFont, message, setMessage]
	);

	const onRemoveImage = (i: number) => () => {
		const nextFiles = attachmentsToBeSent.attachments.filter((_, index) => index !== i);
		attachmentsToBeSent.attachments.forEach((x: Api.FileWithExtensionsType, idx) => {
			if (!!x.url && idx === i) {
				URL.revokeObjectURL(x.url);
			}
		});
		attachmentsToBeSent.remove(attachmentsToBeSent.attachments);
		attachmentsToBeSent.add(Array.from(nextFiles));
	};

	const addFilesToAttachments = React.useCallback(
		(files: FileList) => {
			attachmentsToBeSent.add(Array.from(files));
			if (attachmentsToBeSent.attachments.length) {
				attachmentsToBeSent.attachments.forEach((x: Api.FileWithExtensionsType) => {
					const url = URL.createObjectURL(x);
					x.url = url;
					forceUpdate();
				});
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[attachmentsToBeSent]
	);

	const onFilesInputChanged = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files?.length) {
			addFilesToAttachments(e.target.files);

			e.target.value = null;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onFilesInputRef = React.useCallback((ref?: HTMLInputElement) => {
		filesImportRef.current = ref;
	}, []);

	const onSendMessageClick = React.useCallback(async () => {
		if (hideTextSend) {
			return;
		}

		if (message.trim() || attachmentsToBeSent.attachments.length) {
			if (attachmentsToBeSent.hasExceededMaxByteCount) {
				setShowingSizeLimitWarning(true);
				return;
			}
			try {
				await onSend?.(message, attachmentsToBeSent);
				attachmentsToBeSent.attachments.forEach((x: Api.FileWithExtensionsType) => {
					if (x.url) {
						URL.revokeObjectURL(x.url);
					}
				});
				attachmentsToBeSent.remove(attachmentsToBeSent.attachments);
				setMessage('');
				adjustFont(false);
			} catch (error) {
				logApiError('SendMessage-Error', Api.asApiError(error));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [message, attachmentsToBeSent, onSend, adjustHeight, adjustFont, setMessage]);

	const onCloseSizeWarning = () => {
		setShowingSizeLimitWarning(false);
	};

	const onKeyDown = React.useCallback(
		(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
			if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
				onSendMessageClick();
			}
			adjustHeight();
		},
		[adjustHeight, onSendMessageClick]
	);

	const hiddenInputStyle = React.useRef<React.CSSProperties>({
		height: 0,
		opacity: 0,
		pointerEvents: 'none',
		position: 'fixed',
		visibility: 'hidden',
		width: 0,
		zIndex: -1,
	}).current;

	return (
		<div className={`${css(styleSheet.container)} ${className}`}>
			<div className={css(baseStyleSheet.horizontalStack, styleSheet.toolbar)}>
				<div className={css(styleSheet.toolbarButtonsContainer)}>
					<EmojiPickerButton onEmojiClick={onEmojiClick} />
					<button className={css(styleSheet.transparentBtn)} onClick={onAttachClick}>
						<AttachIcon fillColor={navigation} />
					</button>
				</div>
			</div>
			{attachmentsToBeSent?.attachments?.length > 0 && (
				<div className={css(styleSheet.imageAreaContainer)}>
					{attachmentsToBeSent.attachments.map((file: Api.FileWithExtensionsType, i) => (
						<div className={css(styleSheet.previewContainer)} key={i}>
							{Api.VmUtils?.imageFileExtensions.find(e => file?.type?.includes(e)) ? (
								<div className={css(styleSheet.imageArea)}>
									<img className={css(styleSheet.image)} src={file.url} alt={file.name} />
									<DeprecatedCloseButton
										className={css(styleSheet.closeButton)}
										fillColor={oldSilver}
										onClick={onRemoveImage(i)}
										opacity={0.9}
										outlineColor='#FFF'
										outlineWidth={1.5}
										widthAndHeight={16}
										xMark={true}
									/>
								</div>
							) : (
								<div className={css(styleSheet.fileArea)}>
									<span className={css(styleSheet.fileContainer)}>{file.name}</span>
									<DeprecatedCloseButton
										className={css(styleSheet.closeButton)}
										fillColor={oldSilver}
										onClick={onRemoveImage(i)}
										opacity={0.9}
										outlineColor='#FFF'
										outlineWidth={1.5}
										widthAndHeight={16}
										xMark={false}
									/>
								</div>
							)}
						</div>
					))}

					<ConfirmationDialog
						icon={<WarningIcon />}
						modalProps={{
							isOpen: !!showingSizeLimitWarning,
							onRequestClose: onCloseSizeWarning,
						}}
						title='Max File Size Exceeded'
					>
						<p className={css(styleSheet.sizeWarning)}>
							Reduce your total file size below {Api.convertToMB(Api.MAX_FILE_SIZE_MB)}MB and resend.
						</p>
					</ConfirmationDialog>
				</div>
			)}
			<div
				className={css(
					styleSheet.textAreaContainer,
					attachmentsToBeSent?.attachments?.length > 0 && styleSheet.textAreaWithImages
				)}
			>
				<textarea
					autoFocus={true}
					ref={textAreaRef}
					className={css(styleSheet.textArea)}
					onKeyDown={onKeyDown}
					id='text-message-input'
					onChange={onInputChange}
					placeholder='Enter message'
					rows={1}
					value={message}
				/>
				{!hideTextSend ? (
					<button className={css(styleSheet.transparentBtn, styleSheet.sendMessageBtn)} onClick={onSendMessageClick}>
						<DoubleArrowIcon fillColor={message.length > 0 ? brandPrimaryHover : navigation} />
					</button>
				) : null}
			</div>
			<input
				id='text-message-files-input'
				multiple={true}
				onChange={onFilesInputChanged}
				ref={onFilesInputRef}
				style={hiddenInputStyle}
				type='file'
			/>
		</div>
	);
};

export const TextMessageInput = withEventLogging(observer(TextMessageInputBase), 'TextMessageInput');
