import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
	IImpersonationContextComponentProps,
	IModalContext,
	ImpersonationContextKey,
	ModalChildComponentContextKey,
} from '../../../../models';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { useLocalNotificationService } from '../../../../models/LocalNotifications';
import { useEventLogging } from '../../../../models/Logging';
import { FirstNamePlaceholder, Token } from '../../../../models/Token';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	createRichContentEditorStateWithText,
	getDefaultEmailSignature,
	isValidEmail,
} from '../../../../models/UiUtils';
import { useErrorMessages, useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import { useEmailUserWorkloadQuery } from '../../../../queries';
import * as AppViewModels from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { CompoundButton, CompoundButtonType } from '../../CompoundButton';
import { DeleteConfirmation } from '../../DeleteConfirmation';
import { DeprecatedPopover, PopoverType } from '../../DeprecatedPopover';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Modal } from '../../Modal';
import { ISelectBoxOption } from '../../SelectBox';
import { EmailSuccessToast } from '../../Toaster/EmailSuccessToast';
import { ScheduledEmailErrorModal } from '../../errorMessages/ScheduledEmailErrorModal';
import { AlternateKeyDatesHeader } from '../AlternateKeyDatesHeader';
import { ContactContextTabView } from '../ContactContextTabView';
import { EmailComposer } from '../EmailComposer';
import { EmailComposerOptions } from '../EmailComposerOptions';
import { EmailPreview } from '../EmailPreview';
import { RecipientsField } from '../RecipientsField';
import { SendSchedulerOverlay } from '../SendScheduler';
import { SendSchedulerCompleteOverlay } from '../SendSchedulerCompleteOverlay';
import { styleSheet } from './styles';

interface IProps extends IModalContext, IImpersonationContextComponentProps {
	canScheduleSend?: boolean;
	canShowTemplateSelect?: boolean;
	className?: string;
	emailMessage: AppViewModels.EmailMessageViewModel<File>;
	guideViewModel?: AppViewModels.ComposeEmailToWithGuideViewModel;
	header?: React.ReactNode;
	helpToolTipDisplayTimeout?: number;
	hideCcOption?: boolean;
	hidePreviewButton?: boolean;
	initialRecipientIds?: string[];
	onRenderAdditionalFooterItems?(): React.ReactNode;
	onRequestRemoveSavedEmailAttachment?(savedAttachment: AppViewModels.IFileAttachment): Promise<any>;
	onSend?(sendPromise: Promise<Api.IOperationResultNoValue>): void;
	onWillSend?(emailMessage: AppViewModels.EmailMessageViewModel): boolean;
	sendCtaTitle?: string;
	toastOnSendSuccess?: boolean;
}

function _SendMessageComposer({
	onRenderAdditionalFooterItems,
	hidePreviewButton,
	toastOnSendSuccess,
	parentModal,
	onSend,
	onWillSend,
	sendCtaTitle,
	canScheduleSend,
	onRequestRemoveSavedEmailAttachment,
	hideCcOption,
	canShowTemplateSelect,
	header,
	className,
	emailMessage: emailMessageProp,
	guideViewModel,
	helpToolTipDisplayTimeout,
	impersonationContext,
}: IProps) {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const toaster = useToaster();
	const { logApiError, logInput, logEvent, logEventWithLabel } = useEventLogging('SendMessageComposer');
	const { postNotification } = useLocalNotificationService();

	const [emailMessage] = React.useState<AppViewModels.EmailMessageViewModel<File>>(emailMessageProp);
	const [emailBodyEditorState, setEmailBodyEditorState] = React.useState(
		convertRawRichTextContentStateToRichContentEditorState(emailMessage?.content ?? null)
	);
	const [selectedRecipient, setSelectedRecipient] = React.useState(
		emailMessage?.contactsToAdd && emailMessage.contactsToAdd.length > 0
			? emailMessage.contactsToAdd.getByIndex(0)
			: null
	);
	const [showSendMessageHelp, setShowSendMessageHelp] = React.useState(false);
	const [templates] = React.useState(
		new Api.TemplatesViewModel(userSession, {
			// cannot use impersonationContext and `forUserId` at the same time
			forUserId: !impersonationContext?.isValid ? userSession.user.id : undefined,
		}).impersonate(impersonationContext)
	);
	const [alternateKeyDateTemplates, setAlternateKeyDateTemplates] = React.useState<Api.ITemplate[]>([]);

	const [messageFieldError, setMessageFieldError] = React.useState<string>(null);

	const [subjectFieldError, setSubjectFieldError] = React.useState<string>(null);
	const [showingSendScheduler, setShowingSendScheduler] = React.useState(false);
	const [emailSendEstimateForSentMessage, setEmailSendEstimateForSentMessage] =
		React.useState<Api.IEmailSendEstimate>(null);
	const [showingCcField, setShowingCcField] = React.useState(false);

	const [emailBodyTemplate, setEmailBodyTemplate] = React.useState<Api.ITemplate>(null);

	const [recipientToDelete, setRecipientToDelete] = React.useState<AppViewModels.ContactViewModel>(null);
	const [createFollowupReminder, setCreateFollowupReminder] = React.useState(false);

	const [scheduleError, setScheduleError] = React.useState<AppViewModels.IOperationResultNoValue>(null);
	const [showingPreviewEmail, setShowingPreviewEmail] = React.useState(false);
	const emailWorkloadQuery = useEmailUserWorkloadQuery();

	const onEmailSelectorSelectionChanged = React.useCallback(
		(contact: AppViewModels.ContactViewModel) => (selection: ISelectBoxOption<Api.EmailAddress>) => {
			logInput('EmailSelector', 'Click');
			emailMessage.setPreferredEmailAddressForContact(contact, selection.value);

			setMessageFieldError(null);

			setSubjectFieldError(null);
		},
		[emailMessage, logInput]
	);
	const onAddCcButtonClicked = () => {
		logInput('AddCcField', 'Click');
		setShowingCcField(true);
	};
	const setTemplate = (template: Api.ITemplate) => {
		emailMessage?.setContentWithTemplate(template);
		setEmailBodyEditorState(convertRawRichTextContentStateToRichContentEditorState(template.content));
		setEmailBodyTemplate(template);
	};
	const onBccToCrmCheckedChange = (checked: boolean) => {
		const bccEmail = userSession.user.userPreferences.bccEmail;
		if (bccEmail) {
			const bcc = [...(emailMessage.bcc || [])];
			const index = bcc.findIndex(x => x.email === bccEmail);
			if (checked) {
				if (index < 0) {
					bcc.push({
						email: bccEmail,
					});
				}
			} else {
				bcc.splice(index, 1);
			}
			emailMessage.bcc = bcc;
		}
	};
	const onCreateFollowUpReminderCheckedChanged = (checked: boolean) => {
		setCreateFollowupReminder(checked);

		if (emailMessage?.options) {
			emailMessage.options.followUpIfNoResponseInDays = checked ? 7 : null;
		}
		logInput(`CreateFollowUpReminder:${checked ? 'Checked' : 'Unchecked'}`, 'Click');
	};
	const onFollowUpReminderDaysChanged = (days: number) => {
		const computedDays = Math.max(1, days);
		if (emailMessage?.options) {
			emailMessage.options.followUpIfNoResponseInDays = computedDays;
		}
	};
	const onNotePrivacyToggleChange = (checked: boolean) => {
		emailMessage.options.noteVisibility = checked ? 'all' : 'admin';
	};
	const richTextEditorDidChangeFocus = () => {
		setMessageFieldError(null);

		setSubjectFieldError(null);
	};
	const onMessageEditorStateChanged = (editorState: AppViewModels.IRichContentEditorState) => {
		setEmailBodyEditorState(editorState);

		setMessageFieldError(null);

		setSubjectFieldError(null);
	};
	const onBodyScroll = () => {
		if (!!messageFieldError || !!subjectFieldError) {
			setMessageFieldError(null);

			setSubjectFieldError(null);
		}
	};
	const onEmailMessageTemplateChanged = (template: Api.ITemplate) => {
		if (template) {
			setEmailBodyEditorState(convertRawRichTextContentStateToRichContentEditorState(template.content));
			emailMessage.subject = template.subject || emailMessage.subject;

			if (template.attachments?.length > 0) {
				emailMessage.setSavedAttachments(template.attachments);
			}
		}
	};
	const onSubjectChanged = (subject: string) => {
		emailMessage.subject = subject;

		setMessageFieldError(null);

		setSubjectFieldError(null);
	};
	const send = () => {
		const promise = guideViewModel?.keyFact ? guideViewModel.sendWithResource() : emailMessage.send();
		if (promise) {
			promise
				.then(opResult => {
					if (opResult.value.schedule.criteria === Api.ScheduleCriteria.Immediately) {
						// tell listeners an email was sent
						postNotification({
							info: emailMessage,
							topic: Topics.SEND_EMAIL,
						});
						if (toastOnSendSuccess) {
							const toastMessage: AppViewModels.IAppToastMessage = {
								message: `Message sent successfully.`,
							};

							toaster.push({
								customContent: <EmailSuccessToast toastMessage={toastMessage} />,
								type: 'custom',
							});
						}
						logEventWithLabel('Send-WithSaveAsNote', emailMessage.options.saveAsNote ? 'true' : 'false');
						logEventWithLabel(
							'Send-WithNoteVisibility',
							emailMessage.options.noteVisibility === 'all' ? 'public' : 'private'
						);
						if (parentModal) {
							parentModal.onRequestClose(emailMessage, false);
						}
					} else {
						setEmailSendEstimateForSentMessage({
							emailWorkload: emailWorkloadQuery.data,

							estimate: opResult.value.schedule,
						});
						setShowingSendScheduler(false);
					}
				})
				.catch((error: Api.IOperationResultNoValue) => {
					logApiError('Send-Error', error);
					const sendNow = emailMessage.options?.scheduledSend?.criteria === AppViewModels.ScheduleCriteria.Immediately;
					if (!sendNow && error?.systemCode === -1) {
						// connection error, show special error message
						setScheduleError(error);
						return;
					}

					errorMessages.push({
						messages: [error.systemMessage],
					});
				});

			if (onSend) {
				onSend(promise);
			}
		}
		return promise;
	};
	const onSendButtonClicked =
		(showScheduler = false) =>
		() => {
			if (emailMessage.contactsToAdd.length === 0) {
				return;
			}

			if (!emailMessage.subject) {
				setMessageFieldError(null);
				setSubjectFieldError('Please enter a subject before sending.');
				return;
			}

			if (!emailBodyEditorState || (!!emailBodyEditorState && !emailBodyEditorState.hasContent())) {
				setMessageFieldError('Please enter a message before sending.');

				setSubjectFieldError(null);
				return;
			}

			const content = emailBodyEditorState.getRawRichTextContent();
			if (!Token.isFirstNameTokenFormattedCorrectlyLoose(content)) {
				errorMessages.push({
					messages: [
						`The first name token does not appear to be formatted properly. It should be ${FirstNamePlaceholder.symbol}.`,
					],
				});
				return;
			}

			emailMessage.content = emailBodyEditorState.getRawRichTextContent();

			let canSend = true;
			if (onWillSend) {
				canSend = onWillSend(emailMessage);
			}

			if (!canSend) {
				return;
			}

			if (!!canScheduleSend && !!showScheduler) {
				setShowingSendScheduler(true);
			} else {
				send();
			}
		};
	const previewButtonClicked = () => {
		setShowingPreviewEmail(true);
		logInput('Preview', 'Click');
	};
	const onDismiss = async () => {
		try {
			await guideViewModel?.suppressKeyFact(guideViewModel?.keyFact.id);

			toaster.push({
				message: 'Email Dismissed',
				type: 'successMessage',
			});
		} catch (err) {
			logApiError('Dismiss-Error', err);

			errorMessages.push({
				// @ts-ignore
				messages: [err.systemMessage],
			});
		}
	};
	const onSchedulerSendClicked = (schedule: Api.IScheduledSend) => {
		emailMessage.options.scheduledSend = schedule;
		const sendNow = schedule.criteria === Api.ScheduleCriteria.Immediately;
		logInput(`Send${sendNow ? 'Immediately' : 'WithSchedule'}`, 'Click', {
			count: 1,
			schedule,
		});

		if (!!showingSendScheduler && !!sendNow) {
			setShowingSendScheduler(false);
		}
		return send();
	};
	const onCloseWindowButtonClicked = () => {
		parentModal?.onRequestClose(emailMessage, false);
	};
	const onRemoveRecipient = (recipient: AppViewModels.ContactViewModel) => {
		emailMessage.setPreferredEmailAddressForContact(recipient, null);
		emailMessage.contactsToAdd.removeItems([recipient]);
		setSelectedRecipient(emailMessage.contactsToAdd.getByIndex(0));
	};
	const onDeleteConfirmationFinished = (shouldDelete: boolean) => {
		if (shouldDelete) {
			logEvent('RemoveContact', {
				id: recipientToDelete.id,
			});
			onRemoveRecipient(recipientToDelete);
		}

		setRecipientToDelete(null);

		// close the window if no more recipients remain
		if (emailMessage.contactsToAdd.toArray().length < 1 && !!parentModal) {
			parentModal.onRequestClose(emailMessage, false);
		}
	};

	React.useEffect(() => {
		if (guideViewModel?.keyFact?.keyDate?.kind === Api.KeyDateKind.Birthday) {
			guideViewModel
				.loadAlternateKeyDateTemplates()
				.then(_alternateKeyDateTemplates => {
					setAlternateKeyDateTemplates(_alternateKeyDateTemplates);
				})
				.catch((err: Api.IOperationResultNoValue) => {
					logApiError('LoadAlternateKeyDateTemplates-Error', err);
				});
		}
	}, [guideViewModel, setAlternateKeyDateTemplates, logApiError]);
	React.useEffect(() => {
		if (emailMessage) {
			if (emailMessage.contactsToAdd.length > 0) {
				const firstContact = emailMessage.contactsToAdd.getByIndex(0);
				onEmailSelectorSelectionChanged(firstContact)({
					value: firstContact.primaryEmail || (firstContact.emailAddresses || [])[0],
				});
			}
		}
	}, [emailMessage, onEmailSelectorSelectionChanged]);
	React.useEffect(() => {
		if (emailMessage) {
			if (emailMessage.options) {
				emailMessage.options.followUpIfNoResponseInDays = null;
			}
			emailMessage.contactsToAdd.forEach(x => x.load());
			if (!emailMessage.attachments) {
				emailMessage.setAttachments(
					new AppViewModels.AttachmentsToBeUploadedViewModel<File>(
						null,
						Api.VmUtils.getMaxAttachmentByteCountForEmailConfigurations(
							userSession?.account?.emailProviderConfiguration
						)
					)
				);
			}

			const crmEmail = userSession.user.userPreferences.bccEmail;
			if (crmEmail) {
				// auto-add bcc email
				emailMessage.bcc = [...(emailMessage.bcc || []), { email: crmEmail }];
			}
		}
	}, [emailMessage, userSession]);

	React.useEffect(() => {
		// try to set a default message using a recipient name... if there's only one recipient
		if (!emailBodyEditorState || (!!emailBodyEditorState && !emailBodyEditorState.hasContent())) {
			let name = emailMessage.contactsToAdd.length === 1 ? emailMessage.contactsToAdd.getByIndex(0).firstName : null;
			if (!name && !!emailMessage.contactsToAdd && emailMessage.contactsToAdd.length === 1) {
				name = emailMessage.contactsToAdd.getByIndex(0).firstName || '';
			}
			if (!!name && !isValidEmail(name)) {
				setEmailBodyEditorState(createRichContentEditorStateWithText(`Hi ${name},`));
			}
		}
	}, [emailMessage, emailBodyEditorState, setEmailBodyEditorState]);

	React.useEffect(() => {
		let tooltipTimeoutHandle: NodeJS.Timeout;
		if (userSession?.user?.userMilestones && !userSession.user.userMilestones.hasViewedSendMessageHelp) {
			tooltipTimeoutHandle = setTimeout(() => {
				setShowSendMessageHelp(true);
			}, helpToolTipDisplayTimeout ?? 350);
		}
		return () => {
			if (tooltipTimeoutHandle) {
				clearTimeout(tooltipTimeoutHandle);
			}
		};
	}, [userSession, helpToolTipDisplayTimeout]);

	React.useEffect(() => {
		templates?.signatureTemplates?.reset();
		templates?.signatureTemplates?.getNext()?.then(() => {
			emailMessage.signatureTemplate = getDefaultEmailSignature(templates);
		});
	}, [templates, emailMessage]);

	const renderHeaderContent = () => {
		if (header) {
			return header;
		}

		const name = emailMessage.contactsToAdd.length === 1 ? emailMessage.contactsToAdd.getByIndex(0).name : '';
		const content = (
			<span>
				<span style={{ display: 'flex' }}>
					<span style={{ whiteSpace: 'pre' }}>{'Send '}</span>
					<span className={css(baseStyleSheet.truncateText, styleSheet.headerName)}>{name}</span>
					<span style={{ whiteSpace: 'pre' }}>{' a Message from Your Email'}</span>
				</span>
			</span>
		);

		if (!showSendMessageHelp) {
			return content;
		}

		const markMilestoneComplete = () => {
			const promise = userSession.setUserMilestone('hasViewedSendMessageHelp', true);
			if (promise) {
				promise.catch(error => {
					logApiError('SetUserMilestone-Error', error);
				});
			}

			setShowSendMessageHelp(false);
		};

		const onPopoverClose = () => {
			setShowSendMessageHelp(false);
		};

		return (
			<DeprecatedPopover
				anchor={content}
				dismissOnClickOutside={true}
				isOpen={!!showSendMessageHelp}
				onRequestClose={onPopoverClose}
				preferredPlacement='right'
				type={PopoverType.white}
			>
				<div className={css(styleSheet.popoverContent)}>
					<div className={css(styleSheet.popoverHeader)}>How does this work?</div>
					<div className={css(styleSheet.popoverBody)}>
						When you send an email via Levitate, the email is sent through your normal email system. The recipient will
						receive an email from you, not Levitate.
					</div>
					<div className={css(styleSheet.popoverButtons)}>
						<button className={css(baseStyleSheet.ctaButtonSmall)} onClick={markMilestoneComplete}>
							Got it
						</button>
						<button className='minor-action-link' onClick={markMilestoneComplete}>
							Don&apos;t show me this again
						</button>
					</div>
				</div>
			</DeprecatedPopover>
		);
	};

	function renderAlternateKeyDatesHeader() {
		return (
			<AlternateKeyDatesHeader
				alternateKeyDateTemplates={alternateKeyDateTemplates}
				selectedTemplate={emailBodyTemplate}
				emailMessage={emailMessage}
				onSetTemplate={setTemplate}
			/>
		);
	}

	function renderEmailComposerOptions() {
		const crmEmail = userSession.user.userPreferences.bccEmail;
		return (
			<EmailComposerOptions
				bccToCrm={!!crmEmail && !!emailMessage.bcc.find(x => x.email === crmEmail)}
				createFollowUpReminder={createFollowupReminder || false}
				followUpReminderDays={emailMessage?.options?.followUpIfNoResponseInDays || 7}
				hideCcEmployee={true}
				noteVisibility={emailMessage?.options?.noteVisibility}
				onBccToCrmCheckedChange={onBccToCrmCheckedChange}
				onCreateFollowUpReminderCheckedChanged={onCreateFollowUpReminderCheckedChanged}
				onFollowUpReminderDaysChanged={onFollowUpReminderDaysChanged}
				onNotePrivacyToggleChange={onNotePrivacyToggleChange}
				onSaveAsNoteCheckedChange={checked => {
					emailMessage.options.saveAsNote = checked;
				}}
				saveAsNote={emailMessage?.options?.saveAsNote}
				showBccToCrmOption={!!crmEmail}
			/>
		);
	}

	const sending = !!emailMessage.isSending;
	const showAddCcFieldOption = !showingCcField && !hideCcOption;
	return (
		<div className={`${css(styleSheet.container)} send-message-composer ${className || ''}`}>
			<div
				className={css(!!showingSendScheduler || !!emailSendEstimateForSentMessage ? styleSheet.composerHidden : null)}
			>
				<div className={css(styleSheet.header)}>{renderHeaderContent()}</div>
				<div className={css(styleSheet.body)}>
					<div className={css(styleSheet.bodyInputs)}>
						{emailMessage.contactsToAdd.length === 1 && canShowTemplateSelect ? (
							guideViewModel?.type === Api.ResourceSelectorId.HappyBirthday && renderAlternateKeyDatesHeader()
						) : (
							<RecipientsField
								emailMessage={emailMessage}
								onAddCcButtonClicked={onAddCcButtonClicked}
								onRemoveRecipient={recipient => setRecipientToDelete(recipient)}
								onSelectedRecipientChanged={_selectedRecipient => setSelectedRecipient(_selectedRecipient)}
								selectedRecipient={selectedRecipient}
								showAddCcFieldButton={showAddCcFieldOption}
							/>
						)}
						<EmailComposer
							advancedSettingsPopoverContent={renderEmailComposerOptions()}
							attachments={emailMessage.attachments}
							bodyEditorState={emailBodyEditorState}
							bodyError={messageFieldError}
							ccRecipients={emailMessage.cc}
							className={css(styleSheet.bodyEditor)}
							hideBodyTemplateSelectorButton={guideViewModel?.type === Api.ResourceSelectorId.HappyBirthday}
							individualRecipient={selectedRecipient}
							onBodyEditorFocusChanged={richTextEditorDidChangeFocus}
							onBodyEditorStateChanged={onMessageEditorStateChanged}
							onBodyFieldScroll={onBodyScroll}
							onBodyTemplateChanged={onEmailMessageTemplateChanged}
							onCcRecipientsChanged={(recipients: Api.IRecipient[]) => (emailMessage.cc = recipients)}
							onRequestRemoveSavedEmailAttachment={onRequestRemoveSavedEmailAttachment}
							onSignatureTemplateChanged={(signatureTemplate: Api.ITemplate) => {
								emailMessage.signatureTemplate = signatureTemplate;
							}}
							onSubjectChanged={onSubjectChanged}
							savedAttachments={emailMessage.savedAttachments}
							showCcField={showingCcField && !hideCcOption}
							subject={emailMessage.subject}
							subjectError={subjectFieldError}
							templates={templates}
							signatureTemplate={emailMessage.signatureTemplate}
						/>
						{!!sending && <LoadingSpinner className='absolute-center' type='large' />}
					</div>
					<div className={css(styleSheet.bodyContext)}>
						{!!selectedRecipient && (
							<ContactContextTabView
								className={css(styleSheet.bodyContextTabView)}
								contact={selectedRecipient}
								keyFactToHighlight={!showingSendScheduler ? guideViewModel?.keyFact ?? null : null}
							/>
						)}
					</div>
				</div>
				<div className={css(styleSheet.footer)}>
					{canScheduleSend ? (
						<CompoundButton
							buttonTitle={<span>{sendCtaTitle || 'Send Email'}</span>}
							disabled={templates.signatureTemplates?.isFetching || !!sending}
							onClick={onSendButtonClicked(false)}
							optionsTriggerHoverText='Send Options'
							kind={CompoundButtonType.CtaPrimary}
						>
							<button
								className={css(baseStyleSheet.ctaButtonReverse)}
								disabled={!!sending || !emailWorkloadQuery.data}
								onClick={onSendButtonClicked(true)}
								style={{ width: '100%' }}
							>
								<span>Schedule Send</span>
							</button>
						</CompoundButton>
					) : (
						<button
							className={css(baseStyleSheet.ctaButton)}
							disabled={templates.signatureTemplates?.isFetching || !!sending}
							onClick={onSendButtonClicked(false)}
						>
							<span>{sendCtaTitle || 'Send Email'}</span>
						</button>
					)}
					{!hidePreviewButton ? (
						<button
							disabled={!!sending}
							onClick={previewButtonClicked}
							className={css(baseStyleSheet.ctaButtonReverse)}
						>
							<span>Preview</span>
						</button>
					) : null}
					{guideViewModel?.keyDate?.type ? (
						<button className={css(baseStyleSheet.ctaButtonReverse)} disabled={!!sending} onClick={onDismiss}>
							<span>Dismiss</span>
						</button>
					) : null}
					{!!onRenderAdditionalFooterItems && onRenderAdditionalFooterItems()}
				</div>
			</div>
			{!!showingSendScheduler && (
				<SendSchedulerOverlay
					estimatedEmailTotal={1}
					hideHeader={true}
					hideSendNow={true}
					isBusy={!!emailMessage.isSending}
					onBackButtonClicked={() => setShowingSendScheduler(false)}
					onSend={onSchedulerSendClicked}
					styles={styleSheet.overlay}
				/>
			)}
			{!!emailSendEstimateForSentMessage && (
				<SendSchedulerCompleteOverlay
					emailComposerWithGuide={guideViewModel}
					emailCount={1}
					estimate={emailSendEstimateForSentMessage}
					onCloseButtonClicked={onCloseWindowButtonClicked}
					onNavigateToReportingGroupEmail={onCloseWindowButtonClicked}
					styles={[styleSheet.overlay]}
				/>
			)}
			{!!recipientToDelete && (
				<DeleteConfirmation
					bodyText={
						`Are you sure you'd like to remove ${recipientToDelete.name}?` +
						(emailMessage.contactsToAdd.toArray().length === 1
							? ' Removing the last recipient will close the email composer.'
							: '')
					}
					destructiveButtonText='Remove Contact'
					isOpen={!!recipientToDelete}
					onFinish={onDeleteConfirmationFinished}
				/>
			)}
			{!hidePreviewButton && showingPreviewEmail ? (
				<Modal
					className='bulk-email-composer-email-preview-modal'
					isOpen={true}
					onRequestClose={() => setShowingPreviewEmail(false)}
				>
					<EmailPreview
						emailContent={emailBodyEditorState.getRawRichTextContent()}
						signatureTemplateId={emailMessage.signatureTemplate?.id}
						title={`Preview for ${
							emailMessage.contactsToAdd.length === 1
								? emailMessage.contactsToAdd.getByIndex(0).name || 'contact'
								: 'all contacts'
						}`}
					/>
				</Modal>
			) : null}
			<ScheduledEmailErrorModal
				onNavigateToReportingGroupEmail={onCloseWindowButtonClicked}
				onRequestClose={() => setScheduleError(null)}
				scheduleError={scheduleError}
			/>
		</div>
	);
}

const SendMessageComposerAsObserver = observer(_SendMessageComposer);
export const SendMessageComposer = inject(
	ModalChildComponentContextKey,
	ImpersonationContextKey
)(SendMessageComposerAsObserver);
