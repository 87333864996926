import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import {
	IAutomationInfo,
	IManageAutomationModalResult,
	IModalContext,
	ModalChildComponentContextKey,
} from '../../../../models';
import {
	EnvironmentKey,
	ErrorMessagesViewModelKey,
	IEnvironmentComponentProps,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { Topics } from '../../../../models/LocalNotificationTopics';
import {
	convertHtmlStringValueToPlainText,
	getDefaultDateStringValue,
	getScheduleTimeOptions,
} from '../../../../models/UiUtils';
import { toggleOn } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../ConfirmationDialog';
import { DayPicker } from '../../DayPicker';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { DeprecatedPopover, PopoverType } from '../../DeprecatedPopover';
import {
	INotificationServiceComponentProps,
	withNotificationService,
} from '../../LocalNotificationObserver/WithNotificationService';
import { asModalComponent } from '../../Modal';
import { MoreMenu, MoreMenuItem } from '../../MoreMenu';
import { DefaultSelectBox, ISelectBoxOption } from '../../SelectBox';
import { AutomationStepIcon } from '../../svgs/icons/AutomationStepIcon';
import { CheckmarkIcon } from '../../svgs/icons/CheckmarkIcon';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import { AutomationStepStatusEmailEditorModal } from '../AutomationStepStatusEmailEditor';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		IModalContext<IManageAutomationModalResult>,
		IErrorMessageComponentProps,
		INotificationServiceComponentProps<IAutomationInfo>,
		IUserSessionComponentProps,
		IEnvironmentComponentProps {
	automation: Api.AutomationViewModel;
	className?: string;
	contact?: Api.ContactViewModel;
	onCancel?(): void;
	styles?: StyleDeclarationValue[];
}

interface IState {
	dueMomentToSet?: moment.Moment;
	popoverStepStatus?: Api.IAutomationStepStatus;
	selectedStepStatus?: Api.IAutomationStepStatus;
	showCancelConfirmation?: boolean;
	showCancelStepConfirmation?: boolean;
	showEmailEditor?: boolean;
}

const CancelConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isDestructive: true,
		representedObject: true,
		title: 'Cancel Automation',
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Close',
	},
];

const CancelStepConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isDestructive: true,
		representedObject: true,
		title: 'Cancel Step',
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Close',
	},
];

class _ManageAutomation extends React.Component<IProps, IState> {
	public readonly state: IState = {};

	public render() {
		const { className, styles, automation, contact } = this.props;
		const { showCancelConfirmation, showCancelStepConfirmation, showEmailEditor, selectedStepStatus } = this.state;
		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} manage-automation ${className || ''}`}>
				<div className={css(styleSheet.header)}>Manage Automation</div>
				<div className={css(styleSheet.actionBar)}>
					<div className={css(styleSheet.actionBarName)}>
						<div className={css(baseStyleSheet.tableColumnHeader, styleSheet.actionBarLabel)}>Automation Name</div>
						<NavLink
							className={css(baseStyleSheet.brandLink)}
							title='Edit automation template'
							to={`/automations/${automation?.templateId}`}
						>
							{automation?.name}
						</NavLink>
					</div>
					{(automation?.status?.status === Api.ProcessStatus.Queued ||
						automation?.status?.status === Api.ProcessStatus.Started) && (
						<button className={css(baseStyleSheet.ctaButtonSmall)} onClick={this.onCancelClicked}>
							<span>Cancel Automation</span>
						</button>
					)}
				</div>
				<div className={css(styleSheet.stepsTable)}>
					<div className={css(baseStyleSheet.tableColumnHeader, styleSheet.stepsTableRow)}>
						<div className={css(styleSheet.stepsTableNameCol)}>Steps</div>
						<div className={css(styleSheet.stepsTableSenderCol)}>Sender</div>
						<div className={css(styleSheet.stepsTableStatusCol)}>Status</div>
						<div className={css(styleSheet.stepsTableDateCol)}>Date</div>
						<div className={css(styleSheet.stepsTableActionsCol)}>Actions</div>
					</div>
					{automation?.steps?.map((x, i) => {
						const showMenuButton = !x?.isFinished && !x?.step?.isDependant;
						return (
							<div className={css(styleSheet.stepsTableRow, styleSheet.stepsTableBodyRow)} key={x.step?.id || i}>
								<div
									className={css(
										baseStyleSheet.truncateText,
										baseStyleSheet.horizontalStack,
										styleSheet.stepsTableNameCol
									)}
								>
									<AutomationStepIcon
										styles={[styleSheet.stepsTableBodyRowNameIcon]}
										type={x.step?._type as Api.AutomationStepModelType}
									/>
									<div className={css(baseStyleSheet.truncateText, styleSheet.stepsTableBodyRowName)}>
										{this.renderNameForStepStatus(x)}
									</div>
								</div>
								<div className={css(styleSheet.stepsTableSenderCol)}>
									{this.renderSenderForStepStatus(automation.creator, x)}
								</div>
								<div className={css(styleSheet.stepsTableStatusCol)}>{this.renderStatusForStepStatus(x)}</div>
								<div className={css(styleSheet.stepsTableDateCol)}>{this.renderDateForStepStatus(x)}</div>
								<div className={css(styleSheet.stepsTableActionsCol)}>
									{showMenuButton ? (
										<MoreMenu>
											{x?.status?.status === Api.ProcessStatus.Queued && x?.step?._type === 'EmailAutomationStep' ? (
												<MoreMenuItem onClick={() => this.onActionMenuItemClicked(x)('edit')}>Edit</MoreMenuItem>
											) : null}
											<MoreMenuItem onClick={() => this.onActionMenuItemClicked(x)('cancel')}>Cancel Step</MoreMenuItem>
										</MoreMenu>
									) : null}
								</div>
							</div>
						);
					})}
				</div>
				<ConfirmationDialog
					icon={<WarningIcon />}
					modalProps={{
						isOpen: showCancelConfirmation,
						onRequestClose: this.onCancelConfirmationModalRequestClose,
					}}
					options={CancelConfirmationOptions}
					title='Are you sure you want to cancel this automation?'
				/>
				<ConfirmationDialog
					icon={<WarningIcon />}
					modalProps={{
						isOpen: showCancelStepConfirmation,
						onRequestClose: this.onCancelStepConfirmationModalRequestClose,
					}}
					options={CancelStepConfirmationOptions}
					title='Are you sure you want to cancel this step?'
				/>
				<AutomationStepStatusEmailEditorModal
					automation={automation}
					contact={contact}
					emailStepStatus={
						showEmailEditor ? (selectedStepStatus as Api.IAutomationStepStatus<Api.IEmailAutomationStep>) : undefined
					}
					modalProps={{
						isOpen: showEmailEditor,
						onRequestClose: this.onAutomationEmailEditorModalRequestClose,
					}}
				/>
			</div>
		);
	}

	private renderNameForStepStatus(stepStatus: Api.IAutomationStepStatus) {
		if (stepStatus?.step?.name) {
			return stepStatus.step.name;
		}

		switch (stepStatus?.step._type) {
			case 'EmailAutomationStep': {
				return 'Email Step';
			}
			case 'ActionItemAutomationStep': {
				const actionItemStep = stepStatus.step as Api.IActionItemAutomationStep;
				return convertHtmlStringValueToPlainText(actionItemStep.content?.document) || 'Action Item Step';
			}
			case 'AddTagAutomationStep': {
				const tagStep = stepStatus.step as Api.IAddTagAutomationStep;
				return tagStep.name || `Add Tag: "${tagStep.tagName}"`;
			}
			case 'RemoveTagAutomationStep': {
				const tagStep = stepStatus.step as Api.IRemoveTagAutomationStep;
				return tagStep.name || `Remove Tag: "${tagStep.tagName}"`;
			}
			case 'SwitchAutomationStep': {
				const s = stepStatus.step as Api.ISwitchAutomationStep;
				return s.name || `Segment`;
			}
			case 'NoActionAutomationStep': {
				return 'Do Nothing';
			}
			default: {
				return '';
			}
		}
	}

	private renderSenderForStepStatus(automationCreator: Api.IUserReference, stepStatus: Api.IAutomationStepStatus) {
		const sender = stepStatus.sender?.id ? stepStatus.sender : automationCreator;
		return <span title={sender?.primaryEmail?.value}>{Api.VmUtils.getDisplayName(sender)}</span>;
	}

	private renderStatusForStepStatus(stepStatus: Api.IAutomationStepStatus) {
		let statusText = stepStatus.status?.status || '';
		const textStyles: StyleDeclarationValue[] = [];
		if (stepStatus.status?.status !== Api.ProcessStatus.Completed) {
			textStyles.push(baseStyleSheet.fontBold);
		}

		switch (stepStatus?.step._type) {
			case 'EmailAutomationStep': {
				statusText =
					stepStatus.status?.status === Api.ProcessStatus.Completed ? 'Sent' : stepStatus.status?.status || '';
				break;
			}
			case 'ActionItemAutomationStep':
			case 'AddTagAutomationStep':
			case 'RemoveTagAutomationStep': {
				statusText =
					stepStatus.status?.status === Api.ProcessStatus.Started ? 'Incomplete' : stepStatus.status?.status || '';
				break;
			}
			default: {
				break;
			}
		}
		return (
			<div className={css(...textStyles)}>
				{stepStatus.status?.status === Api.ProcessStatus.Completed ? (
					<div className={css(styleSheet.sentStatus)}>
						<CheckmarkIcon type='bold' fillColor={toggleOn} className={css(styleSheet.sentStatusCheckmark)} />
						<div>{statusText}</div>
					</div>
				) : (
					statusText
				)}
			</div>
		);
	}

	private renderDateForStepStatus(stepStatus: Api.IAutomationStepStatus) {
		const { popoverStepStatus, dueMomentToSet } = this.state;
		const { environment, userSession } = this.props;
		const dateMoment = stepStatus.dueDate ? moment(stepStatus.dueDate) : null;
		let dateStringValue = dateMoment ? getDefaultDateStringValue(dateMoment) : '';
		let hoverText = 'Due date';

		if (
			stepStatus.status?.status === Api.ProcessStatus.Completed ||
			stepStatus.status?.status === Api.ProcessStatus.Failed ||
			stepStatus.status?.status === Api.ProcessStatus.Cancelled ||
			(!!dateMoment && dateMoment.isBefore(moment()) && stepStatus.step?._type !== 'ActionItemAutomationStep') ||
			(stepStatus.step?._type === 'EmailAutomationStep' && stepStatus.status?.status !== Api.ProcessStatus.Queued)
		) {
			switch (stepStatus.status.status) {
				case Api.ProcessStatus.Completed: {
					const originalDueDateString = dateStringValue;

					dateStringValue = getDefaultDateStringValue(moment(stepStatus.status.completedDate));
					hoverText = `Completed on: ${dateStringValue}\n\r(Original due date: ${originalDueDateString})`;
					break;
				}
				case Api.ProcessStatus.Failed: {
					if (stepStatus.status.errorDate) {
						const originalDueDateString = dateStringValue;

						dateStringValue = getDefaultDateStringValue(moment(stepStatus.status.errorDate));
						hoverText = `Failed on: ${dateStringValue}\n\r(Original due date: ${originalDueDateString})`;

						if (stepStatus.status.errorMessage) {
							hoverText += `\n\r${stepStatus.status.errorMessage}`;
						}
					}
					break;
				}
				case Api.ProcessStatus.Cancelled: {
					const originalDueDateString = dateStringValue;

					dateStringValue = getDefaultDateStringValue(moment(stepStatus.status.cancelledDate));
					hoverText = `Canceled on: ${dateStringValue}\n\r(Original due date: ${originalDueDateString})`;
					break;
				}
				default: {
					break;
				}
			}
			return <div title={hoverText}>{dateStringValue}</div>;
		}
		if (!!dateMoment || (!dateMoment && stepStatus.step?._type === 'ActionItemAutomationStep')) {
			const anchor = (
				<button
					className={css(baseStyleSheet.brandLink, styleSheet.stepsTableBodyRowEditableDate)}
					onClick={this.setPopoverStepStatus(stepStatus)}
				>
					<span>{dateMoment ? dateStringValue : 'Set due date'}</span>
				</button>
			);

			let timeDropDown: React.ReactNode;
			if (!!popoverStepStatus && !!dueMomentToSet && stepStatus?.step?._type === 'EmailAutomationStep') {
				const minutes = dueMomentToSet.get('minutes') || 0;
				const hours = dueMomentToSet.get('hours');
				const scheduleOptions = getScheduleTimeOptions(minutes, userSession, undefined, dueMomentToSet);
				timeDropDown = (
					<div className={css(styleSheet.dayPickerPopoverTime)}>
						<DefaultSelectBox
							className={css(styleSheet.dayPickerPopoverTimeDropDown)}
							menuPopupPosition='top'
							onSelectionChanged={this.onTimeOptionSelected}
							options={scheduleOptions}
							optionStyles={[styleSheet.dayPickerPopoverTimeDropdownOption]}
							selectedOption={hours !== null ? scheduleOptions.find(x => x.value === hours) : undefined}
							triggerStyles={[styleSheet.dayPickerPopoverTimeDropdownTrigger]}
						/>
					</div>
				);
			}

			const selectedDate = dueMomentToSet?.toDate();
			return (
				<DeprecatedPopover
					anchor={anchor}
					dismissOnClickOutside={true}
					isOpen={!!popoverStepStatus?.step?.id && popoverStepStatus.step.id === stepStatus?.step?.id}
					onRequestClose={this.setPopoverStepStatus(null)}
					type={PopoverType.white}
				>
					<div className={css(styleSheet.dayPickerPopoverContent)}>
						<div className={css(styleSheet.dayPickerPopoverHeader)}>
							<DeprecatedCloseButton onClick={this.setPopoverStepStatus(null)} />
						</div>
						<DayPicker
							defaultSelectedDay={selectedDate}
							environment={environment}
							onDayClick={this.onDateSelected}
							selectedDays={selectedDate}
						/>
						{timeDropDown}
						<div className={css(styleSheet.dayPickerPopoverFooter)}>
							<button className={css(baseStyleSheet.ctaButtonSmall)} onClick={this.updateDueDate}>
								<span>{`Update date${popoverStepStatus?.step?._type === 'EmailAutomationStep' ? ' & time' : ''}`}</span>
							</button>
						</div>
					</div>
				</DeprecatedPopover>
			);
		}
		return '';
	}

	private setPopoverStepStatus = (popoverStepStatus?: Api.IAutomationStepStatus) => () => {
		const dueMomentToSet = popoverStepStatus?.dueDate
			? moment(popoverStepStatus.dueDate)
			: moment().add(1, 'day').set('hours', 9);
		this.setState({
			dueMomentToSet,
			popoverStepStatus,
		});
	};

	private updateDueDate = () => {
		const { dueMomentToSet, popoverStepStatus } = this.state;
		const { automation, logEvent, errorMessages, logApiError } = this.props;
		const dueDateStringValue = dueMomentToSet?.toISOString();
		if (!!dueDateStringValue && popoverStepStatus?.dueDate !== dueDateStringValue) {
			const promise = automation.setDueDateForStepStatus(popoverStepStatus, dueDateStringValue);
			if (promise) {
				logEvent('SetStepDueDate', {
					date: dueDateStringValue,
					id: automation.id,
					step: {
						_type: popoverStepStatus?.step?._type,
						id: popoverStepStatus?.step?.id,
					},
					stepStatusId: popoverStepStatus?.id,
				});
				promise.catch((error: Api.IOperationResultNoValue) => {
					errorMessages.pushApiError(error);

					logApiError('SetStepDueDate-Error', error);
				});
			}
		}

		this.setPopoverStepStatus(null)();
	};

	private onDateSelected = (date: Date) => {
		const { dueMomentToSet } = this.state;
		const due = moment(date);
		if (dueMomentToSet) {
			due.set('minutes', dueMomentToSet.get('minutes'));
			due.set('hours', dueMomentToSet.get('hours'));
		}
		this.setState({
			dueMomentToSet: due,
		});
	};

	private onTimeOptionSelected = (option: ISelectBoxOption<number>) => {
		const { dueMomentToSet } = this.state;
		if (dueMomentToSet) {
			const due = moment(dueMomentToSet);

			due.set('hours', option.value);
			this.setState({
				dueMomentToSet: due,
			});
		}
	};

	private onAutomationEmailEditorModalRequestClose = () => {
		this.setState({
			showEmailEditor: false,
		});
	};

	private onActionMenuItemClicked = (selectedStepStatus: Api.IAutomationStepStatus) => (item: string) => {
		const { logInput, automation } = this.props;
		switch (item) {
			case 'edit': {
				this.setState({
					selectedStepStatus,
					showEmailEditor: true,
				});
				break;
			}
			case 'cancel': {
				logInput('CancelStep', 'Click', {
					automationId: automation.id,
					stepId: selectedStepStatus?.step?.id,
					stepStatusId: selectedStepStatus.id,
					stepType: selectedStepStatus?.step?._type,
				});
				this.setState({
					selectedStepStatus,
					showCancelStepConfirmation: true,
				});
				break;
			}
			default: {
				break;
			}
		}
	};

	private onCancelStepConfirmationModalRequestClose = (
		result?: IConfirmationDialogOption<boolean>,
		canceled?: boolean
	) => {
		const { selectedStepStatus } = this.state;
		this.setState({
			selectedStepStatus: null,
			showCancelStepConfirmation: false,
		});
		if (!canceled && result?.isDestructive) {
			this.cancelStep(selectedStepStatus);
		}
	};

	private onCancelConfirmationModalRequestClose = (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
		this.setState({
			showCancelConfirmation: false,
		});
		if (!canceled && result?.isDestructive) {
			this.cancelAutomation();
		}
	};

	private onCancelClicked = () => {
		const { logInput, automation } = this.props;

		logInput('Cancel', 'Click', { id: automation.id });
		this.setState({
			showCancelConfirmation: true,
		});
	};

	private cancelStep = (selectedStepStatus: Api.IAutomationStepStatus) => {
		const { logApiError, automation, errorMessages, onCancel } = this.props;
		const promise = automation.cancelStepForStepStatus(selectedStepStatus);
		if (promise) {
			promise.catch((error: Api.IOperationResultNoValue) => {
				logApiError('DeleteStep-Error', error);

				errorMessages.pushApiError(error);
				onCancel?.();
			});
		}
	};

	private cancelAutomation = () => {
		const { automation, logEvent, logApiError, errorMessages, parentModal, contact, onCancel, postNotification } =
			this.props;
		const promise = contact ? contact.cancelInProgressAutomation({ automationId: automation.id }) : automation.cancel();
		if (promise) {
			logEvent('Cancel', { id: automation.id });
			promise
				.then(() => {
					postNotification?.({
						info: {
							automation: automation.toJs(),
							contact: contact?.toJs(),
						},
						topic: Topics.DELETE_AUTOMATION,
					});
					parentModal?.onRequestClose({ cancelled: true }, false);
					onCancel?.();
				})
				.catch((error: Api.IOperationResultNoValue) => {
					errorMessages.pushApiError(error);

					logApiError('Cancel-Error', error);
				});
		}
	};
}

const ManageAutomationAsObserver = observer(_ManageAutomation);
const ManageAutomationWithContext = inject(
	EnvironmentKey,
	ErrorMessagesViewModelKey,
	ModalChildComponentContextKey,
	UserSessionViewModelKey
)(ManageAutomationAsObserver);
const ManageAutomationWithLocalNotifications = withNotificationService(ManageAutomationWithContext);
export const ManageAutomation = withEventLogging(ManageAutomationWithLocalNotifications, 'ManageAutomation');

export const ManageAutomationModal = asModalComponent(ManageAutomation, {
	shouldCloseOnOverlayClick: false,
	useDefaultHeader: false,
	useStyledHeader: true,
	contentPresentationStyle: {
		className: `modal-overlay-content ${css(styleSheet.modalContent)}`,
		transitionInDuration: 450,
		transitionOutDuration: 450,
	},
});
