import * as Api from '@ViewModels';
import { LocationDescriptor } from 'history';
import { parse as getQueryStringParams } from 'query-string';
import * as React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { IUserSessionComponentProps } from '../../../models/AppState';
import { logoutAndClearCaches } from '../../../models/UiUtils';
import { ICredential } from '../../../viewmodels/AppViewModels';
import { LoadingSpinner } from '../../components/LoadingSpinner';

interface IProps<TUserSession extends Api.UserSessionContext = Api.UserSessionContext>
	extends RouteComponentProps<any>,
		IUserSessionComponentProps {
	redirection?: LocationDescriptor<any>;
	userSession: TUserSession;
}

interface IState {
	loading?: boolean;
}

class _CustomerSessionStart extends React.Component<IProps, IState> {
	public state: IState = {
		loading: true,
	};

	public componentDidMount() {
		const { location, userSession } = this.props;
		logoutAndClearCaches();

		const credential = getQueryStringParams(location.search || '') as ICredential;
		userSession.webServiceHelper.getCredentialStore().setCredential(credential);
		userSession.load()?.then(() => {
			this.setState({
				loading: false,
			});
		});
	}

	public render() {
		const { loading } = this.state;
		const { redirection } = this.props;
		if (loading) {
			return <LoadingSpinner type='login' />;
		}

		return <Redirect to={redirection} />;
	}
}

export const CustomerSessionStart = withRouter(_CustomerSessionStart);
