import * as Api from '@ViewModels';
import { ICompany, IPrincipal, getTypeForEntity } from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { getFileSizeStringValue, getPrincipalInitials } from '../../../../models/UiUtils';
import { linkifyText } from '../../../../models/UxUtils';
import { useLambda } from '../../../../models/hooks/useLambda';
import { brandPrimary } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { Avatar2 } from '../../Avatar2';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Modal } from '../../Modal';
import { DangerIcon } from '../../svgs/icons/DangerIcon';
import { DownloadIcon } from '../../svgs/icons/DownloadIcon';
import { FileIcon } from '../../svgs/icons/FileIcon';
import { TextMessageTail } from '../../svgs/icons/TextMessageTail';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	customLabel?: string;
	entity?: Api.IEntity;
	id: string;
	mediaContent?: Api.IFileAttachment;
	placeholder?: JSX.Element;
	showAvatar?: boolean;
	showStatus?: boolean;
	showTimeStamp?: boolean;
	textMessage: Partial<Api.ITextMessage>;
}

export const MessageBubble: React.FC<IProps> = ({
	className = '',
	showAvatar,
	showStatus,
	textMessage,
	entity,
	placeholder,
	mediaContent,
	id,
	showTimeStamp,
	customLabel,
}) => {
	const [imageToOpen, , setImageToOpen] = useLambda<Api.IFileAttachment>(null);
	const { direction, text, errorMessage: error } = textMessage;
	let isImage: string;
	const withEmojis = /\p{Extended_Pictographic}/u;
	const hasLetters = /[a-zA-Z]/;
	const hasNumbers = /[0-9]/;
	const hasSymbols = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
	const isEmoji =
		withEmojis.test(text) &&
		text.length <= 8 &&
		!hasLetters.test(text) &&
		!hasNumbers.test(text) &&
		!hasSymbols.test(text);
	let name = '';
	if (mediaContent) {
		isImage = Api.VmUtils?.imageFileExtensions.find(e => mediaContent?.mimeType?.includes(e));
	}
	if (isEmoji) {
		isImage = 'yes';
	}
	const downloadFile = (file: Api.IFileAttachment) => () => {
		window.open(file.url, '_blank');
	};

	const renderContent = () => {
		if (mediaContent) {
			const split = mediaContent.fileName.split('.');
			const ext = split[split.length - 1];

			if (isImage) {
				return (
					<img
						src={mediaContent.url}
						className={css(
							styleSheet.image,
							textMessage.direction === Api.Direction.Inbound ? styleSheet.imageReceived : styleSheet.imageSent
						)}
						onClick={setImageToOpen(mediaContent)}
					/>
				);
			} else {
				const fileType = ext === 'pdf' ? 'pdf' : 'other';
				return (
					<div className={css(baseStyleSheet.horizontalStack, styleSheet.file)} onClick={downloadFile(mediaContent)}>
						<div className={css(styleSheet.fileIcon)}>
							<FileIcon type={fileType} foldFillColor={brandPrimary} />
							<span>{`.${ext}`}</span>
						</div>
						<div className={css(styleSheet.fileInfo)}>
							<span>{mediaContent.fileName}</span>
							<span>{getFileSizeStringValue(mediaContent.fileSize)}</span>
						</div>
						<div className={css(styleSheet.fileDownload)}>
							<DownloadIcon fillColor={brandPrimary} />
						</div>
					</div>
				);
			}
		}

		if (text) {
			return linkifyText(text);
		}

		if (!text && !mediaContent) {
			return placeholder ?? <LoadingSpinner type='small' />;
		}
	};

	name =
		textMessage.direction === Api.Direction.Outbound
			? textMessage?.creator?.firstName + ' ' + textMessage?.creator?.lastName
			: Api.VmUtils.getDisplayName(entity) ?? null;

	let avatarFallbackText = '';
	let avatarImageSrc = null;
	if (entity) {
		if (getTypeForEntity(entity) === 'company') {
			avatarFallbackText = (entity as ICompany)?.companyName?.substring(0, 1) || '';
		} else {
			const userEntity = entity as IPrincipal;
			avatarFallbackText = getPrincipalInitials(userEntity);
			avatarImageSrc = userEntity?.profilePic;
		}
	}

	const timestamp =
		textMessage.direction === Api.Direction.Outbound
			? textMessage.sentDate ?? textMessage.creationDate
			: textMessage.creationDate;

	return (
		<div
			id={id}
			className={`${css(
				isImage ? styleSheet.containerWithoutBubble : styleSheet.container,
				direction === Api.Direction.Outbound ? styleSheet.containerSent : styleSheet.containerReceived
			)} ${className}`}
		>
			{showTimeStamp && (
				<div className={css(styleSheet.timestamp)}>{`${name ? `${name} ` : ''}${moment(timestamp).format(
					'h:mm a'
				)}`}</div>
			)}
			<div
				className={css(
					baseStyleSheet.horizontalStack,
					styleSheet.bubbleWrapper,
					direction === Api.Direction.Outbound && styleSheet.bubbleSentWrapper
				)}
			>
				{direction === Api.Direction.Inbound && showAvatar && (
					<Avatar2 fallbackText={avatarFallbackText} imgSrc={avatarImageSrc} styleDeclaration={styleSheet.avatar} />
				)}
				<div
					className={css(
						isImage ? styleSheet.noBubble : styleSheet.bubble,
						direction === Api.Direction.Outbound && !isImage
							? styleSheet.bubbleSent
							: isImage
								? undefined
								: styleSheet.bubbleReceived
					)}
				>
					{renderContent()}
					{!isImage && (
						<TextMessageTail
							className={css(
								styleSheet.tail,
								direction === Api.Direction.Outbound ? styleSheet.tailSent : styleSheet.tailReceived
							)}
							color={direction === Api.Direction.Outbound ? brandPrimary : '#ebeeef'}
						/>
					)}
					{!!error && <DangerIcon className={css(styleSheet.errorIcon)} />}
				</div>
			</div>
			{!error && textMessage.status !== Api.TextStatus.Failed && showStatus && (
				<p className={css(styleSheet.status)}>{textMessage.status}</p>
			)}
			{!!error && <p className={css(styleSheet.error)}>{error}</p>}
			{customLabel && <p className={css(styleSheet.status)}>{customLabel}</p>}
			{!error && textMessage.status === Api.TextStatus.Failed && (
				<p className={css(styleSheet.error)}>{error ?? textMessage.status}</p>
			)}
			<Modal isOpen={!!imageToOpen} shouldCloseOnOverlayClick={true} onRequestClose={setImageToOpen(null)}>
				<img className={css(styleSheet.imageFull)} src={imageToOpen?.url} />
			</Modal>
		</div>
	);
};
