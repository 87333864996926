import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import CaretIconUrl from '../../../assets/icon_sort_desc.svg';
import RemoveButtonIconUrl from '../../../assets/removeItemX.svg';
import { baseStyleSheet } from '../../../styles/styles';
import { Dropdown } from '../../Dropdown';
import { styleSheet } from './styles';

type IProps = React.HTMLProps<HTMLDivElement> & {
	text: string;
	selected: boolean;
	hasEmailAddress: boolean;
	emailAddresses?: Api.EmailAddress[];
	hasMultipleEmailAddresses: boolean;
	onEmailSelected: (email: Api.EmailAddress) => void;
	onRemoveItem: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

function _RecipientTag(
	{
		onRemoveItem,
		emailAddresses,
		hasMultipleEmailAddresses,
		text,
		hasEmailAddress,
		onEmailSelected,
		selected,
		onClick,
		...rest
	}: IProps,
	ref: React.Ref<HTMLDivElement>
) {
	const [multiEmailDropdownOpen, setMultiEmailDropdownOpen] = React.useState(false);
	const textWithCaret = (
		<span className={css(baseStyleSheet.truncateText, styleSheet.recipientsFieldTokenText)}>
			<span>{text}</span>
			{!!hasMultipleEmailAddresses && (
				<button
					className={css(styleSheet.recipientsFieldTokenDropdownTrigger)}
					onClick={ev => {
						ev.stopPropagation();
						setMultiEmailDropdownOpen(true);
					}}
				>
					<img src={CaretIconUrl} />
				</button>
			)}
		</span>
	);
	// wrap the token text in a dropdown with email address options, if needed
	const tokenText = hasMultipleEmailAddresses ? (
		<Dropdown
			anchor={textWithCaret}
			contentClassName={css(styleSheet.recipientsFieldTokenDropdownContent)}
			isOpen={multiEmailDropdownOpen}
		>
			<ul className={css(styleSheet.recipientsFieldTokenDropdownMenu)}>
				{emailAddresses.map((x, i) => {
					return (
						<li
							className={css(baseStyleSheet.truncateText, styleSheet.recipientsFieldTokenDropdownMenuItem)}
							key={`email-${i}-${x.value}`}
							onClick={ev => {
								ev.stopPropagation();
								setMultiEmailDropdownOpen(false);
								onEmailSelected(x);
							}}
						>
							{x.value}
						</li>
					);
				})}
			</ul>
		</Dropdown>
	) : (
		textWithCaret
	);

	const tokenClassName = css(
		styleSheet.recipientsFieldToken,
		selected && styleSheet.recipientsFieldTokenSelected,
		!hasEmailAddress && styleSheet.recipientsFieldTokenError
	);

	const token = (
		<div
			{...rest}
			ref={ref}
			className={tokenClassName}
			onClick={ev => {
				ev.stopPropagation();
				if (hasMultipleEmailAddresses) {
					setMultiEmailDropdownOpen(!multiEmailDropdownOpen);
				}
				onClick?.(ev);
			}}
		>
			{tokenText}
			<div className={css(styleSheet.recipientsFieldTokenRemoveButtonContainer)}>
				<button className={css(styleSheet.recipientsFieldTokenRemoveButton)} onClick={onRemoveItem}>
					<img src={RemoveButtonIconUrl} />
				</button>
			</div>
		</div>
	);

	return !hasEmailAddress ? (
		<Dropdown
			anchor={token}
			anchorClassName={css(styleSheet.recipientsFieldErrorTokenDropdownAnchor)}
			className={css(styleSheet.recipientsFieldErrorTokenDropdown)}
			openOnHover={true}
			openOnHoverDelay={600}
		>
			<div className={css(styleSheet.recipientsFieldErrorTokenDropdownContent)}>
				Contact doesn&apos;t have an email address
			</div>
		</Dropdown>
	) : (
		token
	);
}

export const RecipientTag = observer(React.forwardRef(_RecipientTag));
