export const linkifyText = (inputText?: string): React.ReactNode => {
	if (!inputText) return inputText;
	const urlPattern = /(https?:\/\/[^\s]+)/g;

	// Check if the inputText contains any URLs
	if (!urlPattern.test(inputText)) return inputText;

	// Split the text on the matched pattern, then map over parts
	return inputText.split(urlPattern).map((part, index) => {
		// If the part matches a URL, wrap in <a>; otherwise render plain text
		if (part.match(urlPattern)) {
			return (
				<a key={index} href={part} target='_blank' rel='noopener noreferrer'>
					{part}
				</a>
			);
		}
		return part;
	});
};
