import * as Api from '@ViewModels';
import { EmailMessageViewModel } from '@ViewModels';
import { css } from 'aphrodite';
import { useCombobox, useMultipleSelection } from 'downshift';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useDebounceValue } from '../../../../hooks/useDebounceValue';
import { getDisplayName, getPrincipalInitials } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useContactAutocomplete } from '../../../../queries';
import { baseStyleSheet } from '../../../styles/styles';
import { Avatar2 } from '../../Avatar2';
import { LoadingSpinner } from '../../LoadingSpinner';
import { RecipientTag } from './RecipientTag';
import { styleSheet } from './styles';

interface IProps {
	onAddCcButtonClicked?(e: React.MouseEvent<HTMLElement>): void;
	onRemoveRecipient?(recipient: Api.ContactViewModel): void;
	onSelectedRecipientChanged?(selectedRecipient: Api.ContactViewModel): void;
	emailMessage: EmailMessageViewModel<File>;
	selectedRecipient?: Api.ContactViewModel;
	showAddCcFieldButton?: boolean;
}

function _RecipientsField({
	emailMessage,
	showAddCcFieldButton,
	onAddCcButtonClicked,
	selectedRecipient,
	onSelectedRecipientChanged,
	onRemoveRecipient,
}: IProps) {
	const selectedRecipients = emailMessage.contactsToAdd.toArray();
	const userSession = useUserSession();
	const [inputValue, setInputValue] = React.useState('');
	const debouncedInputValue = useDebounceValue(inputValue);
	const { getSelectedItemProps, getDropdownProps } = useMultipleSelection<Api.ContactViewModel>({
		selectedItems: selectedRecipients,
	});
	const { data, isLoading } = useContactAutocomplete({
		enabled: Boolean(debouncedInputValue),
		fragment: debouncedInputValue,
		pageSize: 5,
	});
	const isLoadingContacts = Boolean(debouncedInputValue) && isLoading;
	const items = React.useMemo(() => {
		if (data != null && data.pages[0].values.length) {
			return data.pages[0].values

				.filter(contact => !emailMessage.contactsToAdd.getById(contact.id))
				.map(c => new Api.ContactViewModel(userSession, c));
		}
		return [];
	}, [emailMessage.contactsToAdd, data, userSession]);
	const { isOpen, getLabelProps, getMenuProps, getInputProps, highlightedIndex, getItemProps, selectedItem } =
		useCombobox({
			defaultHighlightedIndex: 0, // after selection, highlight the first item.
			items,
			itemToString(item) {
				return getDisplayName(item);
			},
			onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
				switch (type) {
					case useCombobox.stateChangeTypes.InputKeyDownEnter:
					case useCombobox.stateChangeTypes.ItemClick:
					case useCombobox.stateChangeTypes.InputBlur:
						if (newSelectedItem) {
							emailMessage.contactsToAdd.add(newSelectedItem);
						}
						break;
					case useCombobox.stateChangeTypes.InputChange:
						setInputValue(newInputValue);
						break;
					default:
						break;
				}
			},
			selectedItem: null,
			stateReducer(_, actionAndChanges) {
				const { changes, type } = actionAndChanges;
				switch (type) {
					case useCombobox.stateChangeTypes.InputKeyDownEnter:
					case useCombobox.stateChangeTypes.ItemClick:
						return {
							...changes,
							highlightedIndex: 0, // with the first option highlighted.
							isOpen: true, // keep the menu open after selection.
						};
					default:
						return changes;
				}
			},
		});

	const onEmailSelected = (recipient: Api.ContactViewModel) => (emailAddress: Api.EmailAddress) => {
		emailMessage.setPreferredEmailAddressForContact(recipient, emailAddress);
	};

	return (
		<div className={css(styleSheet.root)}>
			<div className={css(styleSheet.recipientsField)}>
				<label className={css(styleSheet.recipientsFieldLabel)} {...getLabelProps()}>
					To:<span className='required'>*</span>
				</label>
				{emailMessage.contactsToAdd.map((contact, index) => {
					const selectedEmail = emailMessage.getPreferredEmailAddressForContact(contact);
					return (
						<RecipientTag
							key={contact.id}
							onRemoveItem={ev => {
								ev.stopPropagation();

								onRemoveRecipient(contact);
							}}
							emailAddresses={contact.emailAddresses}
							text={!selectedEmail || !selectedEmail.value ? contact.name : selectedEmail.value}
							hasEmailAddress={Boolean(
								contact.primaryEmail || contact.emailAddresses?.findIndex(addr => Boolean(addr.value)) !== -1
							)}
							hasMultipleEmailAddresses={Boolean(contact.emailAddresses && contact.emailAddresses.length > 1)}
							onEmailSelected={onEmailSelected(contact)}
							{...getSelectedItemProps({
								index,
								onClick: () => {
									onSelectedRecipientChanged(contact);
								},
								selected: contact === selectedRecipient,
								selectedItem: contact,
							})}
						/>
					);
				})}
				<div className={css(styleSheet.recipientsFieldInputContainer)}>
					<input
						{...getInputProps(
							getDropdownProps({ className: css(styleSheet.recipientsFieldInput), preventKeyAction: isOpen })
						)}
					/>
					{!!showAddCcFieldButton && (
						<button className={css(styleSheet.recipientsFieldAddCcButton)} onClick={onAddCcButtonClicked}>
							Add Cc
						</button>
					)}
				</div>
			</div>
			<ul
				className={css(
					styleSheet.recipientsFieldDropdown,
					baseStyleSheet.mt1,
					!isOpen && !isLoadingContacts && baseStyleSheet.hidden
				)}
				{...getMenuProps()}
			>
				{isLoadingContacts && (
					<li>
						<LoadingSpinner type='extra-small' />
					</li>
				)}
				{isOpen ? (
					<>
						{items?.map((item, index) => {
							const displayName = getDisplayName(item);
							return (
								<li
									className={css(
										baseStyleSheet.px3,
										baseStyleSheet.py2,
										styleSheet.recipientsFieldDropdownItem,
										highlightedIndex === index && styleSheet.recipientsFieldDropdownItemHighlighted,
										selectedItem === item && styleSheet.recipientsFieldDropdownItemSelected
									)}
									key={`${item.id}${index}`}
									{...getItemProps({ index, item })}
								>
									<Avatar2
										styleDeclaration={styleSheet.recipientsFieldDropdownItemAvatar}
										imgSrc={item.profilePic}
										altText={displayName}
										fallbackText={getPrincipalInitials(item)}
									/>
									<div className={css(styleSheet.recipientsFieldDropdownItemDetails)}>
										<div className={css(styleSheet.recipientsFieldDropdownItemDetailsName)}>{displayName}</div>
										<div className={css(styleSheet.recipientsFieldDropdownItemDetailsEmail)}>
											{item.primaryEmail?.value || item.emailAddresses?.[0]?.value}
										</div>
									</div>
								</li>
							);
						})}
						{data?.pages[0].totalCount && data.pages[0].totalCount > data.pages[0].values.length ? (
							<li className={css(baseStyleSheet.px3, baseStyleSheet.py2, styleSheet.recipientsFieldDropdownItemMore)}>
								and {data.pages[0].totalCount - data.pages[0].values.length} more...
							</li>
						) : null}
					</>
				) : null}
			</ul>
		</div>
	);
}

export const RecipientsField = observer(_RecipientsField);
