import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	userSession: Api.UserSessionContext;
}
export const CustomerImpersonationWarning: React.FC<IProps> = observer(props => {
	const { className = '', styles = [], userSession } = props;
	const [utcExpire, setUtcExpire] = React.useState<number | null>(null);

	React.useEffect(() => {
		const fetchCredential = async () => {
			if (!userSession?.isCustomerImpersonation) {
				return;
			}

			const credential = await userSession.webServiceHelper.getCredentialStore().getCredential();
			if (credential && credential.token_type === 'customer_impersonation') {
				setUtcExpire(credential.expires_utc);
			}
		};

		fetchCredential();
	}, [userSession]);

	return userSession?.isCustomerImpersonation ? (
		<div className={`customer-impersonation-warning ${className} ${css(styleSheet.container, ...styles)}`}>
			👁 REMINDER: You are on a customer account. Only perform the actions necessary to support them. LOG OUT when
			complete. Session is valid until {new Date(utcExpire).toLocaleString()} 👁
		</div>
	) : null;
});
