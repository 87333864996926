import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import {
	AutomationViewModel,
	ContactAutomationsViewModel,
	ContactViewModel,
	IOperationResultNoValue,
	ProcessStatus,
} from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IAutomationInfo, IManageAutomationModalResult, SizeConstraint } from '../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { ILocalNotification } from '../../../../models/LocalNotifications';
import { brandPrimary } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { LocalNotificationObserver } from '../../LocalNotificationObserver';
import { ManageAutomationModal } from '../../automation/ManageAutomation';
import { AutomationsIcon } from '../../svgs/icons/AutomationsIcon';
import { styleSheet } from './styles';

export interface IContactAutomationsProgressBannerComponent {
	showManageModal(automationId: string): void;
}

interface IProps extends IEventLoggingComponentProps, IUserSessionComponentProps {
	className?: string;
	contact: ContactViewModel;
	customTitle?: string;
	onManageClicked?(e: React.MouseEvent<HTMLElement>, automation: AutomationViewModel): void;
	onRef?(ref?: IContactAutomationsProgressBannerComponent): void;
	sizeConstraint?: SizeConstraint;
	styles?: StyleDeclarationValue[];
}

interface IState {
	selectedAutomation?: AutomationViewModel;
}

class _ContactAutomationsProgressBanner
	extends React.Component<IProps, IState>
	implements IContactAutomationsProgressBannerComponent
{
	private mContactAutomations: ContactAutomationsViewModel;

	private mMounted: boolean;

	constructor(props: IProps) {
		super(props);
		this.state = {};

		this.mContactAutomations = new ContactAutomationsViewModel(props.userSession, props.contact);
	}

	public componentDidMount() {
		this.mMounted = true;
		this.load();
		this.props.onRef?.(this);
	}

	public componentWillUnmount() {
		this.mMounted = false;

		this.props.onRef?.(null);
	}

	public render() {
		const { className, customTitle, styles, contact, sizeConstraint } = this.props;
		const { selectedAutomation } = this.state;
		return (
			<div
				className={`${css(styleSheet.container, ...(styles || []))} contact-automations-progress-banner ${
					className || ''
				}`}
			>
				<div className={css(baseStyleSheet.horizontalStack, styleSheet.header)}>
					<AutomationsIcon fillColor={brandPrimary} />
					<span>{customTitle || 'Automations in Progress'}</span>
				</div>
				<div>
					<div className={css(baseStyleSheet.tableColumnHeader, styleSheet.tableRow, styleSheet.tableHeader)}>
						<div className={css(styleSheet.nameCol)}>Automation Name</div>
						{sizeConstraint !== 'compact' && (
							<>
								<div className={css(styleSheet.statusCol)}>Status</div>
								<div className={css(styleSheet.stepsCol)}>Steps</div>
							</>
						)}
						<div className={css(styleSheet.actionCol)}>Action</div>
					</div>
					<ul>
						{this.mContactAutomations.isLoading ? (
							<li className={css(styleSheet.tableBodyRowLoading)} key='loading'>
								<LoadingSpinner type='small' />
							</li>
						) : (
							this.mContactAutomations.automations?.map(x => {
								const statusDescription = x.status?.status === ProcessStatus.Started ? 'In Progress' : x.status?.status;
								return (
									<li className={css(styleSheet.tableRow, styleSheet.tableBodyRow)} key={x.id}>
										<div className={css(baseStyleSheet.brandLink, baseStyleSheet.truncateText, styleSheet.nameCol)}>
											<button
												className={css(baseStyleSheet.brandLink, baseStyleSheet.truncateText)}
												onClick={this.onAutomationSelected(x)}
											>
												<span>{x.name}</span>
											</button>
										</div>
										{sizeConstraint !== 'compact' && (
											<>
												<div className={css(baseStyleSheet.truncateText, styleSheet.statusCol)}>
													{statusDescription}
												</div>
												<div className={css(baseStyleSheet.truncateText, styleSheet.stepsCol)}>
													{x.steps?.length > 0 ? `${x.numberOfCompletedSteps} of ${x.steps.length} complete` : ''}
												</div>
											</>
										)}
										<button
											className={css(baseStyleSheet.brandLink, styleSheet.actionCol)}
											onClick={this.onAutomationSelected(x)}
										>
											<span>Manage</span>
										</button>
									</li>
								);
							})
						)}
					</ul>
				</div>
				<LocalNotificationObserver
					topic={Topics.CREATE_AUTOMATION}
					onNotificationReceived={this.onNotificationReceived}
				/>
				<ManageAutomationModal
					automation={selectedAutomation}
					contact={contact}
					modalProps={{
						isOpen: !!selectedAutomation,
						onRequestClose: this.onMangeModalRequestClose,
					}}
				/>
			</div>
		);
	}

	public showManageModal = (automationId: string) => {
		const showModal = () => {
			const selectedAutomation = this.mContactAutomations.automations?.find(x => x.id === automationId);
			if (selectedAutomation) {
				this.setState({
					selectedAutomation,
				});
			}
		};
		if (this.mContactAutomations.isLoading) {
			this.mContactAutomations.load()?.then(() => {
				showModal();
			});
		} else {
			showModal();
		}
	};

	private onNotificationReceived = (notification?: ILocalNotification<IAutomationInfo>) => {
		const { contact } = this.props;
		if (notification?.info?.contact?.id === contact.id) {
			this.load();
		}
	};

	private onAutomationSelected = (selectedAutomation: AutomationViewModel) => (e: React.MouseEvent<HTMLElement>) => {
		this.props.onManageClicked?.(e, selectedAutomation);
		if (!e.defaultPrevented) {
			this.setState({
				selectedAutomation,
			});
		}
	};

	private onMangeModalRequestClose = (result?: IManageAutomationModalResult, cancel?: boolean) => {
		const { selectedAutomation } = this.state;
		if (!cancel && result?.cancelled) {
			this.mContactAutomations.automations.removeItems([selectedAutomation]);
		}
		if (this.mMounted) {
			this.setState({
				selectedAutomation: null,
			});
		}
	};

	private load = () => {
		const { contact, logApiError, logEvent } = this.props;
		const promise = this.mContactAutomations.load();
		if (promise) {
			logEvent('LoadAutomations', { contactId: contact.id });
			promise.catch((error: IOperationResultNoValue) => {
				logApiError('LoadAutomations-Error', error);
			});
		}
	};
}

const ContactAutomationsProgressBannerAsObserver = observer(_ContactAutomationsProgressBanner);
const ContactAutomationsProgressBannerWithContext = inject(UserSessionViewModelKey)(
	ContactAutomationsProgressBannerAsObserver
);
export const ContactAutomationsProgressBanner = withEventLogging(
	ContactAutomationsProgressBannerWithContext,
	'ContactAutomationsProgressBanner'
);
